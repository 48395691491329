body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
   /* UI Properties */
   position: relative;
   min-height: 100vh;
   width: 100vw;
   overscroll-behavior: contain
  /* background: var(--bgColor) 0% 0% no-repeat padding-box; */
  /* opacity: 1; */

}
html, body {
  overflow-x: hidden;
}