/* body {
  background: var(--bgColor) 0% 0% no-repeat padding-box ;
  opacity: 1;
} */

.qr-modal {
  /* position: fixed; */
  width: 300;
  height: 300;
  /* left: 50%;
  transform: translate(-50%,-50%);
  top: 50%; */
}

.qr-modal>* {
  margin-bottom: 100px;

}

.share-btn {
  background: transparent linear-gradient(90deg, #12A79E 0%, #2AB573 100%) 0% 0% no-repeat padding-box;
  width: 203px;
  height: 52px;
  line-height: 52px;
  margin-bottom: 30px;
  font-weight: bold;
  justify-content: center;
  align-content: center;
  text-align: center;
  margin-left: auto;
  box-shadow: 0px 5px 6px #0000004A;
  margin-right: auto;
  border-radius: 26px;
}

.ar-button {
  position: fixed;
  z-index: 10;
  width: 96px;
  height: 40px;
  left: -20px;
  top: 83px;
  overflow: visible;
  color: white
}

.ar-button:hover {
  color: white
}

.ar-button>.ar-bg {
  height: 100%;
  padding-top: 6px;
  background: transparent linear-gradient(90deg, var(--bgColor) 0%, var(--secondColor) 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 6px #0000004A;
  border-radius: 26px;
  opacity: 1;
}
/* keyframe light blink*/
@keyframes blink {
  0% {
    opacity: 0;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1.5);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}

.blink {
  animation: blink 5s infinite;
}


/* animation rotate */
.ar-button>.ar-bg>img {
  animation: rotate 2s infinite;
}
.rotate-animation {
  animation: rotate 2s infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.ar-button>.ar-bg:hover {
  background: transparent linear-gradient(90deg, var(--bgColor) 0%, var(--secondColor) 100%) 0% 0% no-repeat padding-box;
}

.ar-button>.ar-bg>img {
  margin-left: 5px;
  margin-top: -6px;
}

.ar-button>.ar-bg>span {
  font-size: 20px;
  margin-left: 10px;
}

.App-bg {
  position: fixed;
  min-height: 100vh;
  width: 100vw;
  background: var(--bgColor) 0% 0% no-repeat padding-box;
}

.App {
  opacity: 1;
  color: white;
  text-align: center;
  font-family: "Prompt", Times, serif;
}

.card-container {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  width: 428px;
  max-width: 100vw;
  min-height: 100%;
}

.profile-img {
  position: relative;
  width: 428px;
  max-width: 100vw;
  height: 400px;
}

.profile-img>.com-name {
  position: absolute;
  /* Layout Properties */
  z-index: -3;
  top: 160px;
  font-size: 20px;
  left: -160px;
  width: 400px;
  transform: matrix(0, -1, 1, 0, 0, 0);
  /* UI Properties */
  text-align: left;
  /* font: normal normal 600 11px/14px Montserrat; */
  letter-spacing: 0px;
  color: #ffffff7e;
  text-shadow: 0px 3px 6px #00000029;
  opacity: 0.68;
}

.profile-img>.com-name-2 {
  position: absolute;
  z-index: -9;
  font-weight: bold;
  /* Layout Properties */
  top: 100px;
  font-size: 100px;
  left: 80px;
  width: 300px;
  /* UI Properties */
  text-align: left;
  /* font: normal normal 600 11px/14px Montserrat; */
  letter-spacing: 0px;
  color: var(--mainColor);
  text-shadow: 0px 3px 6px #00000029;
  opacity: 0.1;
}

.drop-shadow {
  -webkit-filter: drop-shadow(2px 2px 2px rgba(34, 34, 34, 0.691));
  filter: drop-shadow(2px 2px 2px rgba(34, 34, 34, 0.703));
}

/* class animation opacity */
.fade-in {
  animation: fadeUp ease 10s;
}

@keyframes fadeUp {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* fade left */
.fade-left {
  animation: fadeLeft ease 10s;
}

@keyframes fadeLeft {
  0% {
    transform: translateX(100px);
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.profile-img>.logo {
  -webkit-filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.365));
  filter: drop-shadow(5px 5px 5px rgba(34, 34, 34, 0.431));
  position: absolute;
  z-index: -2;
  /* Layout Properties */
  top: 0px;
  right: 120px;
  width: 300px;
  height: 300px;
  /* UI Properties */
  opacity: 1;
}

.profile-img>.bg {
  position: absolute;
  top: 0px;
  z-index: -10;
  width: 428px;
  max-width: 100vw;
  /* ปรับตรงนี้้ */
  left: 50%;
  transform: translate(-50%, 0);
  height: 525px;
  background: transparent linear-gradient(66deg, var(--secondColor) 0%, var(--mainColor) 100%) 0% 0% no-repeat padding-box;
  opacity: 1;
}

.card-container>.container {
  width: 368px;
  margin-left: auto;
  margin-right: auto;
}

.profile-img>.bg-blur {
  position: absolute;
  /* Layout Properties */
  z-index: -10;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100vw;
  height: 572px;
  /* UI Properties */
  background: transparent linear-gradient(180deg, #14002500 0%, #1602275C 60%, #140025ED 75%, #140025 80%) 0% 0% no-repeat padding-box;
  opacity: 1;
}

.profile-img>.user-img {
  position: absolute;
  z-index: -1;
  top: -50px;
  left: 60px;
  width: 600px;
}

.user-img-bg-blur {
  position: absolute;
  z-index: -1;
  top: 440px;
  left: 90px;
  width: 600px;
  height: 310px;
  /* UI Properties */
  /* background: transparent linear-gradient(180deg, var(--linear01) 0%, var(--linear02) 10%, var(--linear03) 75%, var(--linear04) 80%) 0% 0% no-repeat padding-box; */
  background: transparent linear-gradient(180deg, #14002500 0%, #1602275C 60%, #140025ED 75%, #140025 80%) 0% 0% no-repeat padding-box;
  /* background-color: white ; */
  opacity: 1;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.phone-info {
  padding: 9px 14px 9px 14px;
  margin-bottom: 10px;
  display: flex;
  height: 37px;
  background: transparent linear-gradient(279deg, #FFFFFF26 0%, #FFFFFF40 100%) 0% 0% no-repeat padding-box;
  border: 0.5px solid #FFFFFF29;
  border-radius: 19px;
  opacity: 1;
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
}

.phone-info>p {
  padding-left: 9px;
  font-size: 16px;
  line-height: 1;
  display: inline-block;
}

.blur {
  filter: blur(4px);
  position: absolute;
  z-index: -1;
  border-radius: 100px;
}

.user-name {
  /* display: flex; */
  /* flex-wrap: wrap; */
  width: 100%;
}

.user-name>h1 {
  text-align: left;
  text-shadow: 0 0 10px var(--secondColor);
}

.user-name>h1>span {
  font-size: 20px;
}

.user-info {
  text-align: left;
  font-size: 17px;
  text-shadow: 0 0 10px var(--secondColor);
}

.company-name {
  text-align: left;
  font-size: 17px;
  line-height: 1;
  text-shadow: 0 0 10px var(--secondColor);
}

.user-name>h2 {
  text-shadow: 0 0 10px var(--secondColor);
  flex: 1 1 auto;
  padding: 0 0 5px 10px;
  line-height: 1;
  display: inline-block;
  font-size: 25px;
  align-self: flex-end;
  position: relative;
  bottom: 0px;
  /* UI Properties */
  text-align: left;
  font: normal normal 600 25px/30px;
  letter-spacing: 0px;
  color: #FFF4FD;
  opacity: 1;
}

/* description-card */
.description-card>.bg {
  z-index: 0;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 20px;
  width: 368px;
  height: 422px;
  /* UI Properties */
  background: transparent linear-gradient(330deg, var(--bgColor) 0%, var(--secondColor) 39%, var(--mainColor) 100%) 0% 0% no-repeat padding-box;
  border: 0.30000001192092896px solid #FFFFFFFD;
  border-radius: 22px;
  opacity: 1;
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
}

h1.description-card-topic {
  font-size: 20px;
}

.description-card>.header {
  position: relative;
  height: 50px;
}

.description-card>.info {
  margin-top: 20px;
  text-align: left;
  overflow-wrap: break-word;
  padding-left: 17px;
  padding-right: 17px;
  position: relative;
  width: 100%;
}

.description-card>.header>p {
  font-size: 24px;
  text-align: left;
  padding-top: 10px;
  margin-left: 80px;
  line-height: 60px;
}

.description-card.topic {
  position: absolute;
  left: 1px;
  z-index: 2;
}

.front {
  position: absolute;
  z-index: 1;
}

.back {
  position: absolute;
  z-index: -1;
}

.about-icon.shadow {
  position: absolute;
  z-index: -1;
  left: 10px;
  top: 10px;
  border-radius: 13px;
  opacity: 1;
  width: 40px;
  height: 40px;
}

.about-icon {
  top: 20px;
  left: 20px;
  width: 40px;
  height: 40px;
  /* UI Properties */
  background: transparent linear-gradient(317deg, #FFFFFF1C 0%, #FFFFFF22 100%) 0% 0% no-repeat padding-box;
  border-radius: 13px;
  opacity: 1;
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* group */
.link-button.group {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

a {
  color: #ceb2ff;
  text-decoration: none;
}

.link-button>a {
  margin-bottom: 5px;
  position: relative;
  padding: 9px 9px 9px 9px;
  width: 65px;
  height: 65px;
  /* UI Properties */
  background: transparent linear-gradient(317deg, #FFFFFF2B 0%, #FFFFFF24 100%) 0% 0% no-repeat padding-box;
  border-radius: 17px;
  opacity: 1;
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
}