.ar-popup-content{
    width: 100vw;
    min-height: 100vh;
    background-color: rgba(0, 0, 0, 0.841);
    position: fixed;
    top: 0;
    left: 0;
    padding: 20px;
    transform: translate(0, 0);
    z-index: 100;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}
.ar-popup-header{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.ar-popup-body{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.ar-popup-item{
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    border-color: rgb(251, 255, 255);
    border-width: 1px;
    width: 280px;
    margin-top: 20px;
    border-style: solid;
    background-color: rgb(0, 0, 0);
    padding: 10px 20px 5px 20px;
    color:rgb(251, 255, 255)
}