#org-template-01 .org-header {
  position: absolute;
  left: 0%;
  right: 0%;
  top: 0%;
  height: 100px;
  width: 100px;
  background: linear-gradient(175.65deg, #04AF6F 4.38%, #00977F 51.12%);
}
#org-template-01 .org-logo{
  animation: logo-move 3s infinite;
}
/* keyframe scale up-down */
@-webkit-keyframes scale-up-down {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
#org-template-01 .logo-bg-1{
  position: absolute;
  left: 0px;
  top: 60px;
  opacity: 0.03;
  scale: 1.5;
  animation: logo-move 5s infinite;
}
#org-template-01 .logo-bg-2{
  position: absolute;
  right: -30px;
  top: -50px;
  opacity: 0.03;
  scale: 1.5;
  animation: logo-move 5s infinite;
}
#org-template-01 .logo-bg-3{
  position: absolute;
  right: -30px;
  top: 250px;
  opacity: 0.03;
  scale: 1.5;
  animation: logo-move 5s infinite;
}
/* keyframe logo move left-right */
@keyframes logo-move {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}
#org-template-01 .org-card {
  border-radius: 50px 50px 0px 0px;
  min-height: 100vh;
  width: 428px;
  max-width: 100vw;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 40px;
  padding-bottom: 20px;
  background: linear-gradient(175.65deg, #17835b 4.38%, #03816c 51.12%);
  /* background-color: #00977F; */
}

#org-template-01 .user-list {
  position: relative;
  /* flex */
  display: flex;
  /* flex row */
  flex-direction: row;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  /* mouse hover */
  cursor: pointer;
  min-height: 130px;
  width: 100%;
}

#org-template-01 .user-list>.user-image {
  position: absolute;
  width: 20px;
  left: 60px;
  top: -15px;
}

#org-template-01 .user-list>.user-image>.user {
  position: absolute;
  z-index: 2;
  width:100px;
}

#org-template-01 .user-list>.user-image>.logo {
  position: absolute;
  width:150px;
  height:100px;
  /* image cover */
  object-fit: contain;
  -webkit-filter: drop-shadow(2px 2px 2px rgba(34, 34, 34, 0.691));
  filter: drop-shadow(2px 2px 2px rgba(34, 34, 34, 0.703));
  left: -50px;
  top: 20px;
  z-index: 1;
}

#org-template-01 .user-info {
  /* margin-top: 15px; */
  margin-left: 180px;
  color: rgb(255, 255, 255);
}

#org-template-01 .user-info>h2 {
  font-size: large;
  text-transform: uppercase;
  font-weight: bold;
  text-shadow: none;
  font-size: 13px;
}

#org-template-01 .user-info>h1 {
  text-shadow: none;
  margin-left: -10px;
  font-size: 13px;
  font-weight: bold;
  background-color: var(--mainColor);
  border-radius: 15px;
  max-width: 200px;
  padding: 5px 10px 5px 10px;
  color: rgb(255, 255, 255);
}

#org-template-01 .user-info>p {
  margin-top: -5px;
  text-shadow: none;
  font-size: small;
  font-weight: bold;
  color: rgb(199, 199, 199);
  margin-bottom: 0px;
}